.rmc-picker,
.rmc-multi-picker {
  height: 238px;
  /*34*7*/
}
.rmc-multi-picker {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.rmc-picker-item {
  font-size: 24px;
  height: 34px;
  line-height: 34px;
  padding: 0 10px;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #FFFFFF;
  width: 100%;
  box-sizing: border-box;
}

.rmc-picker-item:not(.rmc-picker-item-selected)  {
  color: #505050;
  font-size: 20px;
}
.rmc-picker {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  -webkit-box-flex: 1;
  flex: 1;
  text-align: center;
}
.rmc-picker-mask {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  z-index: 3;
  background-position: top, bottom;
  background-size: 100% 204px;
  background-repeat: no-repeat;
}
.rmc-picker-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.rmc-picker-indicator {
  box-sizing: border-box;
  width: 20%;
  height: 34px;
  position: absolute;
  left: 40%;
  top: 102px;
  z-index: 3;
  border-top: 3PX solid #07BBA6;
  border-bottom: 3PX solid #07BBA6;
}
